import { componentLayouts, containerLayouts, itemLayouts, type ComponentStructure } from 'root/utils/OOI/consts';

export const createWidgetDefinition = ({
  appDefinitionId,
  widgetId,
  tpaAppId,
}: {
  appDefinitionId: string;
  widgetId: string;
  tpaAppId: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): any => {
  return {
    componentType: 'wysiwyg.viewer.components.tpapps.TPASection',
    skin: 'wysiwyg.viewer.skins.TPASectionSkin',
    layout: undefined,
    layoutResponsive: {
      id: '',
      type: 'LayoutData',
      componentLayouts,
      containerLayouts,
      itemLayouts,
    },
    style: 'ref1',
    data: {
      applicationId: `${tpaAppId}`,
      type: 'TPA',
      appDefinitionId,
      widgetId,
    },
  };
};

export const createSectionDefinition = (components: ComponentStructure[]): ComponentStructure => {
  return {
    type: 'Container',
    components,
    componentType: 'responsive.components.Section',
    layouts: {
      componentLayout: {
        type: 'ComponentLayout',
        height: {
          type: 'auto',
        },
        minHeight: {
          type: 'px',
          value: 500,
        },
      },
      itemLayout: {
        id: '',
        type: 'GridItemLayout',
        gridArea: {
          rowStart: 1,
          columnStart: 1,
          rowEnd: 2,
          columnEnd: 2,
        },
        alignSelf: 'stretch',
        justifySelf: 'stretch',
      },
      containerLayout: {
        type: 'GridContainerLayout',
        rows: [
          {
            type: 'fr',
            value: 1,
          },
        ],
        columns: [
          {
            type: 'fr',
            value: 1,
          },
        ],
      },
      type: 'SingleLayoutData',
    },
  };
};
